const API_URL = process.env.REACT_APP_authServiceURL;

const Apis = {

    //User API
    UserLogout: `${API_URL}/user/logout`,
    UserRenewToken: `${API_URL}/user/renew/token`,

    GetautoFetchAddressByGstNo: (gstNumber) => `${API_URL}/gst/validate/get?gstin=${gstNumber}`,
    GetLicenseCodeByEmail: (email) => `${API_URL}/merchant/fetch-code?email=${email}`,

    CreateMerchantRegistrationCode: `${API_URL}/merchant/registration`,

    //Merchant Login and Sign up
    MerchantLogin: `${API_URL}/merchant/login`,
    MerchantSignupRequest: `${API_URL}/merchant/signup`,

    //Individual Login
    IndividualLogin: `${API_URL}/individual/login`,

    //Location API
    GetCountryData: `${API_URL}/backend/region/countries`,
    GetStatesData: (countryName) => `${API_URL}/backend/region/states/${countryName}`,
    GetCitiesData: (stateName) => `${API_URL}/backend/region/cities/${stateName}`,

    GetMerchantByCustomUrl: (data) => `${API_URL}/merchant/get-by-customurl?customUrl=${data?.customUrl}`,
    GetUserDataByCustomUrl: (data) => `${API_URL}/${data?.type === "INDIVIDUAL" ? "individual" : "merchant"}/get-by-customurl?customUrl=${data?.customUrl}`,
    UpdateMerchantProfile: `${API_URL}/merchant/update`,

    CookieAcceptance: `${API_URL}/user/acceptCookies`,
    UploadAttachment: `${API_URL}/backend/file/uploadAttachment`,

    //Bank Details
    CreateBankDetails: `${API_URL}/bank/save`,
    GetBankDetails: (merchantId) => `${API_URL}/bank/get/merchantId?merchantId=${merchantId}`,
    UpdateBankDetails: `${API_URL}/bank/update`,
    GetautoFetchBankName: (ifscCode) => `${API_URL}/bank/get-bank-name?ifscCode=${ifscCode}`,

    //Tax Information API
    CreateUserTaxinformationData: `${API_URL}/tax-information/save`,
    TaxInformationData: (id) => `${API_URL}/tax-information/get?userId=${id}`,
    UpdateUserTaxinformationData: `${API_URL}/tax-information/update`,

    MLGetFilterCount: (data) => `${API_URL}/recommended/user-filter-count/?userId=${data?.userId}`,

    //Products API
    GetProductsData: (data) => `${API_URL}/store/product/get-by-merchant?merchantId=${data?.merchantId}&searchQuery=${data?.searchQuery}&searchType=${data?.searchType}&brandId=${data?.brand}&categoryId=${data?.category}&page=${data?.page}&size=${data?.size}`,
    UpdateProductStatus: `${API_URL}/store/product/update-status`,
    CreateProduct: `${API_URL}/store/product/create`,
    GetProductById: (id) => `${API_URL}/store/product/get?id=${id}`,
    UpdateProduct: `${API_URL}/store/product/update`,

    //Product Categories Api
    GetProductCategories: `${API_URL}/store/product-category/public/list`,

    GetMerchantCodeStatus: (data) => `${API_URL}/merchant/get-code-status?email=${data?.email}&code=${data?.code}`,
    VerifyMerchantPhoneNumber:  `${API_URL}/otp/merchant_phone_number_verify_otp_send`,
    OtpVerify: `${API_URL}/otp/verify`,
    ResendMerchantPhoneNoVerifyOtp: (transactionId) => `${API_URL}/otp/merchant_phone_number_verify_otp_re_send?transactionId=${transactionId}`,

    PasswordChangeOtp: (data) => `${API_URL}/otp/${data?.type === "individual" ? "individual_password_change_otp_send" : "password_change_otp_send_merchant"}?email=${data?.email}`,
    ResendMerchantOtpForgotPasword: (transactionId) => `${API_URL}/otp/password_change_otp_re_send_merchant?transactionId=${transactionId}`,
    ChangePassword:(data)=> `${API_URL}/${data?.type === "individual" ? "individual" : "merchant"}/change_password`,
    GetProductConfigurationByMerchantId: (merchantId) => `${API_URL}/store/merchant-config/get-data-by-merchantId?merchantId=${merchantId}`,

    /* Shop Filter Api */
    GetUserFilterConfigData: (data) => `${API_URL}/store/user-filter/get?userId=${data?.userId}`,
    ApplyShopFilter: `${API_URL}/store/user-filter/save-update`,
    
    /* Product API */
    GetUserProductsById: (data) => `${API_URL}/store/product/auth/global-search?userId=${data?.userId}&page=${data?.page}&size=${data?.size}`,
    GetGlobalUnathProducts: `${API_URL}/store/product/unauth/global-search`,
    GetProductDetailsById: (data) => `${API_URL}/store/product/get?id=${data?.productId}&userId=${data?.userId}`,
    GetProductDetailsBySlug: (data) => `${API_URL}/store/product/get/slug?slug=${data?.slug}&userId=${data?.userId}`,

    /* WishList API */
    GetWishListSaveItems: (data) => `${API_URL}/store/wishlist/list?userId=${data?.userId}&page=${data?.page}&size=${data?.size}`,
    AddItemToWishList: `${API_URL}/store/wishlist/add`,
    RemoveItemFromWishList: (data) => `${API_URL}/store/wishlist/delete?userId=${data?.userId}&productId=${data?.productId}`,

    /* Cart API */
    GetCartListDataByUserId: (userId) => `${API_URL}/store/cart/list?userId=${userId}`,
    AddItemToCartList: (data) =>  `${API_URL}/store/cart/add?userId=${data?.userId}&productId=${data?.productId}`,
    GetShoppingActivityCounts: (data) => `${API_URL}/store/cart/count?userId=${data?.userId}`,
    UpdateItemQuantity: (data) => `${API_URL}/store/cart/change-count?userId=${data?.userId}&productId=${data?.productId}&changeCount=${data?.changeCount}`,
    RemoveItemFromCart: (data) => `${API_URL}/store/cart/delete?userId=${data?.userId}&productId=${data?.productId}`,
    ProceedToCheckout:  (userId) => `${API_URL}/cart/checkout?userId=${userId}`,

    //Individual otp
    IndividualOtpVerify: `${API_URL}/otp/individual/signup`,
    IndividualOtpResend: (transactionId) => `${API_URL}/otp/individual_signup_otp_resend?transactionId=${transactionId}`,

    // Redemtion Api 
    GetRedemptionByMerchantId: (data) => `${API_URL}/store/redemption/list?merchantId=${data?.merchantId}&searchType=${data?.searchType}&searchQuery=${data?.searchQuery}&page=${data?.page}&size=${data?.size}`,
    GetRedemptionDetailsById: (data) => `${API_URL}/store/redemption/list-by-code?merchantId=${data?.merchantId}&redemptionCode=${data?.redemptionCode}&page=${data?.page}&size=${data?.size}`,
    GetProductRedemionsData: (data) => `${API_URL}/store/redemption/list-by-product?merchantId=${data?.merchantId}&page=${data?.page}&size=${data?.size}`,

    //Payment API
    CreatePayment: `${API_URL}/payment/create_order`,
    PaymentUpdate: `${API_URL}/payment/update_payment`,
    WalletPaymentUpdate: `${API_URL}/payment/update_wallet`,

    /* You may like Api  */
    GetYoumaylikeProductData: (data) => `${API_URL}/recommend/recommended/you-might-like/?userId=${data?.userId}&productId=${data?.productId}&eventType=${data?.eventType}`,

    //Shipment Apis
    GenateShipmentCode: `${API_URL}/store/shipment/generate-shipment-code`,
    GetShipmentList: (data) => `${API_URL}/store/shipment/list?searchQuery=${data?.searchQuery}&searchType=${data?.searchType}&merchantId=${data?.merchantId}`,
    CreateShipment: `${API_URL}/store/shipment/save`,
    UpdateShipment: `${API_URL}/store/shipment/update`,
    GetShipmentByShipmentCode: (shipmentCode) => `${API_URL}/store/shipment/get-by-shipment-code?shipmentCode=${shipmentCode}`,
    GetShipmentDetailsById: (id) => `${API_URL}/store/shipment/get?id=${id}`,

    //Order List API
    GetOrderList: (data) => `${API_URL}/store/order/get?userId=${data?.userId}&page=${data?.page}&size=${data?.size}`,

    // Merchant Subscription Status
    GetMerchantSubscriptionStatus: (merchantId) => `${API_URL}/merchant/subscription/status?merchantId=${merchantId}`,

    CheckCandidateProfile: (userId) =>`${API_URL}/user/check-candidate?userId=${userId}`,

    GetPendingRedemptionCount :(merchantId) => `${API_URL}/store/redemption/pending-count?merchantId=${merchantId}`,

    GetUserSubscriptionDetails: (userId) =>`${API_URL}/user/store-validate?userId=${userId}`,
};

export { API_URL, Apis };
